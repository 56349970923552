import React from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withI18next from '~/../hocs/_withI18next';
import Config from '~/config';

@withI18next()
class FormInput extends React.Component {
  render() {
    const {
      label,
      input,
      require,
      type,
      style,
      placeholder,
      meta: { error, touched },
      t,
    } = this.props;
    return (
      <div className="form-group">
        {label && <label htmlFor={label}>{label}</label>}
        {label && require && (
          <FontAwesomeIcon
            style={{ width: '1em' }}
            className="mypage-text-primary"
            icon={faCircle}
          />
        )}
        <input
          id={label}
          className="form-control input-acphoto"
          {...input}
          type={type}
          placeholder={placeholder}
          style={style}
        />

        <div className={`error-messages ${touched && error && 'show-message'}`}>
          {touched && error && (
            <div className="text-center">
              <div
                className={`${Config.getPrefixContent(
                  'bg-yellow',
                )} field-message-error animated`}
              >
                {t(`${error}`)}
              </div>
            </div>
          )}
        </div>
        <style jsx global>
          {`
            .ac-form-input {
              position: relative;
            }
            .ac-form-input input {
              min-height: 55px;
              border: 1px solid #b0b0b0;
              border-radius: 2px;
              font-size: 16px;
            }
            .ac-form-input .error-messages {
              -webkit-transition: height 0.35s ease-in-out;
              -moz-transition: height 0.35s ease-in-out;
              -ms-transition: height 0.35s ease-in-out;
              -o-transition: height 0.35s ease-in-out;
              transition: height 0.35s ease-in-out;
              height: 0;
            }
            .ac-form-input .show-message {
              -webkit-transition: height 0.35s ease-in-out;
              -moz-transition: height 0.35s ease-in-out;
              -ms-transition: height 0.35s ease-in-out;
              -o-transition: height 0.35s ease-in-out;
              transition: height 0.35s ease-in-out;
              height: 37px;
            }
            .ac-form-input .arrow-up {
              top: 0;
              position: absolute;
              display: inline-block;
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
            }
            .ac-form-input .error-messages {
              position: relative;
            }
            .ac-form-input .error-messages .field-message-error {
              padding: 5px;
            }
            .ac-form-input .error-messages .field-message-error {
              top: 7px;
              z-index: 100;
              border-radius: 2px;
              position: absolute;
              width: 100%;
              color: #fff;
              text-align: center;
              box-shadow: 0 3px 5px 0 rgba(176, 176, 176, 0.8);
            }
            .ac-form-input .animated {
              animation-duration: 1s;
              animation-fill-mode: both;
            }
            .ac-form-input .show-message {
              -webkit-transition: height 0.35s ease-in-out;
              -moz-transition: height 0.35s ease-in-out;
              -ms-transition: height 0.35s ease-in-out;
              -o-transition: height 0.35s ease-in-out;
              transition: height 0.35s ease-in-out;
            }
          `}
        </style>
      </div>
    );
  }
}

export default FormInput;
