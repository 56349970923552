import { format } from 'url';
import React from 'react';
import { connect } from 'react-redux';
import defaultPage, { defaultPageServerSideContext } from './defaultPage';
import config from '~/config';
import ErrorPage from '~/containers/ErrorPage';
import { redirect } from '~/utils';
import { USER_ROLES } from '~/utils/constants';
import { loadProfile } from '~/containers/AuthProvider/actions';

/*
Usage: Only use this for pages
*/
const securePageHoc = (Page, roles) => {
  class SecurePage extends React.Component {
    componentDidMount() {
      const { isAuthenticated, profile, loadProfile } = this.props;
      if (isAuthenticated && profile) {
        loadProfile(profile);
      }
    }

    render() {
      const { isAuthenticated, profile } = this.props;
      if (isAuthenticated && !roles.includes(profile?.role)) {
        return <ErrorPage statusCode={404} />;
      }
      return <Page {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    loadProfile: (profile) => dispatch(loadProfile(profile)),
  });

  return connect(null, mapDispatchToProps)(SecurePage);
};

const securePage = (roles = [], namespaces = ['common']) => (Page) =>
  defaultPage(namespaces)(securePageHoc(Page, roles));
export default securePage;

export async function securePageServerSideContext(ctx) {
  ctx = await defaultPageServerSideContext(ctx);
  const { isCreator, roles, profile, isAuthenticated, currentLanguage } = ctx;

  console.log(
    'isCreator, roles, profile, isAuthenticated, currentLanguage',
    isCreator,
    roles,
    profile,
    isAuthenticated,
    currentLanguage,
  );

  // isAuthenticated and currentLanguage are from getInitialProps of defaultPage HOC
  const guestAllowed = roles.includes(USER_ROLES.GUEST);

  if (!isAuthenticated && !guestAllowed) {
    // User is a guest
    let redirectURL;

    if (roles.includes(USER_ROLES.CREATOR) && isCreator) {
      // Access my page of creator
      redirectURL = config.getSiteURL() + '/login';
      // const loginUrl = '/login';
      // const query = {};
      // if (pageName === 'RegisterPremiumPage' || pageName === 'PremiumPage') {
      //   query.redirect = 'premium';
      // }
      // redirectURL = format({
      //   pathname: loginUrl,
      //   query,
      // });
    } else {
      // Access to pages for downloader, such as: mypage of downloader, cancel premium, etc.
      // let fullURL = '';
      // if (ctx.req) {
      redirectURL = config.getSiteURL(null, currentLanguage);
      // } else {
      //   fullURL = location.protocol + '//' + location.host + location.pathname;
      // }
      // redirectURL = `${config.ssoServerUrl}/login?lang=${
      //   currentLanguage || 'en'
      // }&serviceURL=${encodeURIComponent(fullURL)}`;
    }
    redirect(ctx, redirectURL);
    return ctx;
  }

  if (isAuthenticated) {
    // For logged in users

    let pathLanguage, pathRemaining;
    // if (ctx.req) {
    //   pathLanguage = config.supportedLanguages.find(
    //     (lang) =>
    //       ctx.req.originalUrl === `/${lang}` ||
    //       ctx.req.originalUrl.startsWith(`/${lang}/`),
    //   );
    //   pathRemaining = pathLanguage
    //     ? ctx.req.originalUrl.substring(3)
    //     : ctx.req.originalUrl;
    // } else {
    //   pathLanguage = config.supportedLanguages.find(
    //     (lang) =>
    //       location.pathname === `/${lang}` ||
    //       location.pathname.startsWith(`/${lang}/`),
    //   );
    //   pathRemaining = pathLanguage
    //     ? location.pathname.substring(3)
    //     : location.pathname;
    // }
    // pathLanguage = pathLanguage || process.env.LANGUAGE;
    pathLanguage = currentLanguage;
    pathRemaining =
      ctx.req.originalUrl.indexOf(`/${pathLanguage}`) === 0
        ? ctx.req.originalUrl.substring(3)
        : ctx.req.originalUrl;
    // console.log('ppppppppppppp', pathLanguage, pathRemaining);

    if (
      profile?.role !== USER_ROLES.CREATOR &&
      !roles.includes(profile?.role)
    ) {
      console.log('vo day ney!!!');
      // Downloader user and access to secure pages
      redirect(ctx, config.getSiteURL());
      return ctx;

      // if (
      //   pathRemaining === '/login' ||
      //   pathRemaining === '/creator-registration'
      // ) {
      //   redirect(ctx, config.getSiteURL()); // redirect to home page
      //   return ctx;
      // }

      // let fullURL = '';
      // // if (ctx.req) {
      // fullURL = config.getSiteURL() + ctx.req.originalUrl;
      // // } else {
      // //   fullURL = location.protocol + '//' + location.host + location.pathname;
      // // }
      // const redirectURL = `${config.ssoServerUrl}/login?lang=${
      //   ctx.currentLanguage || 'en'
      // }&serviceURL=${encodeURIComponent(fullURL)}`;
    } else if (profile?.role === USER_ROLES.CREATOR) {
      // Logged in user is creator
      if (!roles.includes(profile?.role)) {
        // Creator user access to page only for guest, such as login page
        redirect(ctx, config.getSiteURL());
        return ctx;
      } else if (!config.isCreatorAvailable(pathLanguage)) {
        // Access to routes for creator but in different language
        // Then redirect to Taiwan site of creator
        let redirectURL;
        // if (ctx.req) {
        redirectURL = config.getSiteURL(null, 'tw') + pathRemaining;
        // } else {
        //   redirectURL = pathRemaining;
        // }
        redirect(ctx, redirectURL);
        return ctx;
      }
    }
  }

  return ctx;
}
