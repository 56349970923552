import { createSelector } from 'reselect';

const selectAccountType = state => state.accountType;

const makeSelectAccountType = () => createSelector(
  selectAccountType,
  metadataState => metadataState,
);

export {
  selectAccountType,
  makeSelectAccountType,
};
