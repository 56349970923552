import isNumber from 'lodash/isNumber';

function validateEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

function validatePhoneNumber(phone) {
  return /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(phone);
}

function validateNumber(number) {
  return isNumber(number);
}

export { validateEmail };
export { validatePhoneNumber };
export { validateNumber };
